import {findIndex,cloneDeep} from "lodash"

// mutation types
export const SET_SELECTED_INSPECTIONS = "setSelectedInspections";
export const SET_SELECTED_INSPECTIONS_ID = "setSelectedInspectionsId";
export const SET_ORDER_DETAILS = "setOrderDetails";
export const SET_RETRY_TRANSACTION = "setRetryTransaction";
export const REMOVE_INSPECTION = "removeInspection";
export const SET_INSPECTION = "setInspection";
export const SET_GST = "setGst";
export const SET_GST_DATA = "setGstData";
export const SET_DISCOUNT_DATA = "setDiscountData";
export const SET_DISCOUNT_STATUS = "setDiscountStatus";
export const SET_EWALLET_AMOUNT = "setEwalletAmount";
export const SET_VEHICLE_DETAILS = "setVehicleDetails";
export const SET_CASHBACK = "setCashback";


export const SET_ERROR = "setError";

const state = {
	errors: [],
	//products: [],
	order_details:{},
	selected_products_id: [],
	selected_products: [],
	vehicle_details: {},
	retryTransaction:false,
	selected_inspections_id: [],
	selected_inspections: [],
	rate_of_gst:0,
	gst_data:{},
	discount_data:[],
	discount_status:false,
	related_product:[],
	ewallet_amount:0,
	discount_list:[],
	overdue_amount:0,
	cashback_details:{}
};

const getters = {
	// products(state) {
	// 	return state.products;
	// },
	selected_products(state) {
		return state.selected_products;
	},
	vehicle_details(state) {
		return state.vehicle_details;
	},
	retryTransaction(state) {
		return state.retryTransaction;
	},
	selected_inspections(state) {
		return state.selected_inspections;
	},
	selected_inspections_id(state){
		return state.selected_inspections_id
	},
	order_details(state){
		return state.order_details;
	},
	rate_of_gst(state){
		return state.rate_of_gst;
	},
	gst_data(state){
		return state.gst_data;
	},
	discount_data(state){
		return state.discount_data;
	},
	discount_status(state){
		return state.discount_status;
	},
	related_product(state){
		return state.related_product;
	},
	ewallet_amount(state){
		return state.ewallet_amount;
	},
	discount_list(state){
		return state.discount_list;
	},
	overdue_amount(state){
		return state.overdue_amount;
	},
	cashback_details(state){
		return state.cashback_details;
	}
};

const actions = {
	[REMOVE_INSPECTION](context,data){
		let products = cloneDeep(context.state.selected_inspections);
		const Index = findIndex(products, {
			Id: data.id,
			Type: data.type,
		});
		products.splice(Index,1);
		context.commit(SET_INSPECTION,products)
	},
	
};

const mutations = {
	[SET_EWALLET_AMOUNT](state, data){
		state.ewallet_amount = data;
	},
	[SET_SELECTED_INSPECTIONS](state, data) {
		state.selected_inspections = data;
	},
	[SET_SELECTED_INSPECTIONS_ID](state, data) {
		state.selected_inspections_id = data;
	},
	[SET_INSPECTION](state, data) {
		state.selected_inspections = data;
	},
	[SET_ORDER_DETAILS](state,data){
		state.order_details = data;
	},
	[SET_GST](state,data){
		state.rate_of_gst = data;
	},
	[SET_GST_DATA](state,data){
		state.gst_data = data;
	},
	[SET_DISCOUNT_DATA](state,data){
		state.discount_data = data;
	},
	[SET_DISCOUNT_STATUS](state,data){
		state.discount_status = data;
	},
	[SET_ERROR](state, error) {
		state.errors = error;
	},
	[SET_VEHICLE_DETAILS](state, data){
		state.vehicle_details = data;
	},
	[SET_CASHBACK](state, data){
		state.cashback_details = data;
	},
	[SET_RETRY_TRANSACTION](state, data){
		state.retryTransaction = data;
	},
};

export default {
	state,
	actions,
	mutations,
	getters,
};
