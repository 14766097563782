import MomentJS from "moment-timezone";
import { find } from "lodash";

class MainSetting {
	install(Vue) {
		Vue.mixin({
			data() {
				return {
					deviceWidth: window.innerWidth,
					deviceHeight: window.innerHeight,
					paymentModeArr: [
						{
							id: 1,
							paymentBanklogo: "https://staging-junma.bthrust.com/api/storage/app/images/customer/9nSriv3HIrAIJDBZ2kG1ZSJ3nehhpm1SGldoX09r.png",
							paymentBankName: "Visa/Master",
						},
						{
							id: 2,
							paymentBanklogo: "https://staging-junma.bthrust.com/api/storage/app/images/customer/xeiagBqU2eGAig25bzDzPN7EnG6yxk7sf13qIQkU.png",
							paymentBankName: "American Express",
						},
						{
							id: 3,
							paymentBanklogo: "https://staging-junma.bthrust.com/api/storage/app/images/customer/oao7KkF3r29Qfes4GpD8NULQDBXdPhY5qC4W9jjs.png",
							paymentBankName: "NETS",
						},
						{
							id: 4,
							paymentBanklogo: "https://staging-junma.bthrust.com/api/storage/app/images/customer/yOfplAX7ADe7xW67eydsECSCZ01aEk7slJtGheHz.png",
							paymentBankName: "NETSQR/PayLah/PayNow",
						},
					],
					imageLinks : {
						netsLogo : "https://staging-junma.bthrust.com/api/storage/app/images/customer/oao7KkF3r29Qfes4GpD8NULQDBXdPhY5qC4W9jjs.png",
						cashPayment : "https://staging-junma.bthrust.com/api/storage/app/images/customer/gHQlUXNoCMROho9B7rKbQiyhxo3ogRwY4KAljPJF.png",
						americanExpressLogo : "https://staging-junma.bthrust.com/api/storage/app/images/customer/xeiagBqU2eGAig25bzDzPN7EnG6yxk7sf13qIQkU.png",
						freeCheck : "https://staging-junma.bthrust.com/api/storage/app/images/customer/QE9EbLYoaojxb4V6nssvOQ5RGaY52eTc7N354BwJ.png",
						insertMachine : "https://staging-junma.bthrust.com/api/storage/app/images/customer/Lob0ExdLpa2SqPeQyB7E90ifGU3h3vfROssIHtBk.png",
						dollorLogo : "https://staging-junma.bthrust.com/api/storage/app/images/customer/qj0j0vjHLeVxz0LB8whvdklS6LS825ug6oBsWJgt.png",
						masterTransparent : "https://staging-junma.bthrust.com/api/storage/app/images/customer/uohM8vx8j6ObjLEIYZ2IPkbmHDWEyI6i1MmjvIFZ.jpg",
						netsQr : "https://staging-junma.bthrust.com/api/storage/app/images/customer/lF1jq8GutLOg9Aqah43zlLuKAKVhjfGqPMTzzvJt.png",
						payNow : "https://staging-junma.bthrust.com/api/storage/app/images/customer/xzHugqOafJNFIOYWCOiTfBDxS5RnFD79d76aoRCi.png",
						payLaPaynow : "https://staging-junma.bthrust.com/api/storage/app/images/customer/yOfplAX7ADe7xW67eydsECSCZ01aEk7slJtGheHz.png",
						payNow2 : "https://staging-junma.bthrust.com/api/storage/app/images/customer/guJ1rR3kdo8NPhd9nRFE8lbTRCGf8VsoTSxC95b9.png",
						newDollor : "https://staging-junma.bthrust.com/api/storage/app/images/customer/Lf5VslIlxTqkwSBMKVErbtfas9GCBk9aTsLEshRe.jpg",
						netsVending : "https://staging-junma.bthrust.com/api/storage/app/images/customer/U5xGkHf0pbcZ20IaDm5i7HDk1GcatES7HXpbMFeG.png",
						success : "https://staging-junma.bthrust.com/api/storage/app/images/customer/5Ze5fGDhDdijAeQDI4DuOB7B96WZqY7JRMbs5onq.png",
						tapCard : "https://staging-junma.bthrust.com/api/storage/app/images/customer/8VIZfHVbihCqlw6r0YHM1iRSGsJODLB65mNLj8yH.png",
						visaMasterCard : "https://staging-junma.bthrust.com/api/storage/app/images/customer/9nSriv3HIrAIJDBZ2kG1ZSJ3nehhpm1SGldoX09r.png"
					}
				}
			},
			created() {
				window.addEventListener("resize", this.resizeEventHandler);
			},
			destroyed() {
				window.removeEventListener("resize", this.resizeEventHandler);
			},
			methods: {
				goBack() {
					this.$router.go(-1);
				},
				getRoute(pathString) {
					let pathData = `file://${__static}${pathString}`;
					return pathData;
				},
				fetchPaymentMethod(id) {
					let paymentData = find(this.paymentModeArr, (row) => row.id == id);
					return paymentData;
				},
				async logError(error) {
					try {
						console.warn(error);
					} catch (error) {
						console.warn(error);
					}
				},
				setContentHeight() {
					//console.log({'route': this.$route});
					let element = document.querySelector('.height-fix');
					if (element) {
						let bodyRect = document.body.getBoundingClientRect();
						if (bodyRect) {
							let elemRect = element.getBoundingClientRect();
							let offset = elemRect.top - bodyRect.top;
							element.style.cssText = `
								height:${window.innerHeight - (offset)}px;
								overflow:auto;
							`;
						}
					}
				},
				openCart() {
					if (this.deviceWidth < 920) {
						const element = document.querySelector('#app');
						element.classList.add('cart-opened');
					}
				},
				closeCart() {
					const element = document.querySelector('#app');
					const closeElement = document.querySelector('.cart-overlay');
					if (closeElement) {
						closeElement.onclick = () => {
							element.classList.remove('cart-opened');
						}
					}
				},
				resizeEventHandler() {
					this.deviceWidth = window.innerWidth;
					this.deviceHeight = window.innerHeight;
					const _this = this;
					setTimeout(() => {
						_this.setContentHeight();
					}, 100)
				},
				formatDateTime(dateTime) {
					return MomentJS(dateTime).format("DD/MM/YYYY hh:mm a");
				},
				formatDateTimeRaw(dateTime) {
					return MomentJS(dateTime).format("YYYY-MM-DD HH:mm");
				},
				formatDate(date) {
					return MomentJS(date).format("DD/MM/YYYY");
				},
				formatDateRaw(date) {
					return MomentJS(date).format("YYYY-MM-DD");
				},
				roundUpDown(number) {
					const n = parseInt(parseFloat(number).toFixed(2) * 100);
					const rem = n % 5;
					let diff = 0;
					if (rem <= 2) {
						diff = rem;
						return ((n - diff) / 100).toFixed(2);
					}
					if (rem >= 3) {
						diff = 5 - rem;
						return ((n + diff) / 100).toFixed(2);
					}
					return diff;
				},
				formatAmount(amount) {
					let text = "$0.00";
					if (amount > 0) {
						text = `$${amount.toFixed(2)}`
					}
					return text;
				}
			},
			mounted() {
				const _this = this;
				setTimeout(() => {
					_this.setContentHeight();
				}, 100)
				_this.closeCart();
			},
			errorCaptured(err, vm, info) {
				this.logError({ err, vm, info });
			},
		})
	}
}
export default new MainSetting();