import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: "/",
        redirect: "/login",
        component: () => import("@/views/layout/Layout"),
        children: [
            {
                path: 'dashboard',
                name: 'dashboard',
                component: () => import('@/views/pages/Dashboard.vue')
            },
            {
                path: 'payment-counter',
                name: 'payment-counter',
                component: () => import('@/views/pages/PaymentCounter.vue')
            },
            {
                path: 'search-vehicle',
                name: 'search-vehicle',
                component: () => import('@/views/pages/SearchVehicle.vue')
            },
            {
                path: 'detail-vehicle/:id',
                name: 'detail-vehicle',
                component: () => import('@/views/pages/vehicleDetail.vue')
            },
            {
                path: 'select-discount',
                name: 'select-discount',
                component: () => import('@/views/pages/SelectDiscount.vue')
            },
            {
                path: 'payment-selection',
                name: 'payment-selection',
                component: () => import('@/views/pages/PaymentSelect.vue')
            },
            {
                path: 'payment-confirm/:id',
                name: 'payment-confirm',
                component: () => import('@/views/pages/PaymentConfirm.vue')
            },
            {
                path: 'payment-qr',
                name: 'payment-qr',
                component: () => import('@/views/pages/QRpayPage.vue')
            },
            {
                path: 'transaction-decline',
                name: 'transaction-decline',
                component: () => import('@/views/pages/TransactionDecline.vue')
            },
            {
                path: 'success-page',
                name: 'success-page',
                component: () => import('@/views/pages/SuccessPage.vue')
            },
            {
                path: 'orders',
                name: 'orders',
                component: () => import('@/views/pages/orders/Orders')
            },
        ]
    },
    {
        name: "authenticate",
        path: "/",
        component: () => import("@/views/pages/auth/Authenticate"),
        children: [
            {
                path: "login",
                name: "login",
                component: () => import("@/views/pages/auth/Login"),
            },
        ],
    },
    /* {
        path: "*",
        redirect: "/404",
    },
    {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/views/pages/error/Error"),
    }, */
]

const router = new VueRouter({
    mode: 'hash',
    routes
})

export default router
